// const tns = require('tiny-slider');
const Flickity = require('flickity')

window.addEventListener('click', e => {
  if (e.target.hasAttribute('data-togglemenu')) {
    document.querySelectorAll('.nav-btn').forEach(el => el.classList.toggle('on-nav-active'));
    document.querySelector('.wrapper').classList.toggle('on-nav-active');
  } else if (e.target.hasAttribute('data-contact')) {
    document.querySelector('.wrapper').classList.toggle('on-contact-active');
  }
});

function _onload() {

  if (document.getElementById('slider')) {
    const slider = new Flickity('#slider', {
      cellAlign: 'center',
      wrapAround: true
    });
  }

}

window.addEventListener('load', _onload);